<template>
  <div
    id="home"
    dark
    :class="{ active: buzzer.toggle, 'prevent-scroll': isViewOpen }"
    class="pt-14"
  >
    <v-scroll-y-reverse-transition mode="out-in">
      <router-view
        :blocker="blocker"
        :alerts="alerts"
        :user="user"
        :active="active"
        :installed="installed"
        :dev="dev"
      />
    </v-scroll-y-reverse-transition>

    <referrals 
      ref="referrals" 
    />
    
    <profile 
      :toggle="view.profile.toggle"
      ref="profile" 
      @forceUpdate="toggleUpdate(true)"
    />

    <tos />

    <privacy-policy />

    <help 
      v-if="helpContent!=null"
      :toggle="view.help.toggle"
      :content="helpContent"
      :alerts="alerts"
      :reviews="view.review.data"
      :hive="hive"
    />

    <v-dialog
      v-model="view.alert.toggle"
      max-width="90vw"
      max-height="80vh"
      persistent
      content-class="alert-dialog"
      overlay-opacity="1"
      z-index="3"
    >
      <v-card 
        v-if="view.alert.notification!=null"
        class="overbar primary--text pt-3"
      >
        <v-card-title class="flex-nowrap">
          <span class="white--text">{{ view.alert.notification.title }}</span>
          <v-spacer />
          <v-icon 
            color="primary"
            class="ml-4"
          >
            {{ icons.message }}
          </v-icon>
        </v-card-title>

        <v-card-text 
          v-html="view.alert.notification.text" 
          ref="alert-text"
          class="alert-dialog-text scrollable text-body-1 pb-12 pt-3"
        />
        <v-btn
          v-show="view.alert.toggle&&view.alert.dismissable"
          fab
          dense
          absolute
          right
          bottom
          dark
          color="grey darken-4"
          @click="toggleAlert(false)"
        >
          <v-icon color="primary">{{ icons.check }}</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="standbyConfirmation.toggle"
      max-width="290"
      persistent
    >
      <v-card class="pt-3">
        <v-card-title>
          {{ standbyConfirmation.headline }}
        </v-card-title>

        <v-card-text v-html="standbyConfirmation.text" />
        
        <v-card-actions>
          <v-spacer />

          <v-btn
            text
            color="error"
            @click="setStatus(false)"
          >
            {{ standbyConfirmation.buttons.ok.text }}
          </v-btn>

          <v-btn
            text
            color="primary"
            @click="toggleStandbyConfirmation(false)"
          >
            {{ standbyConfirmation.buttons.cancel.text }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay
      :value="block"
      color="background"
      opacity="1"
      persistent
      z-index="3"
    >
      <v-card 
        class="pa-4 ma-6 black"
      >
        <v-card-title>
          {{ blocker.headline }}

          <v-btn
            v-if="blocker.dismissable"
            icon
            absolute
            right
            top
            dark
            color="grey"
            @click="blockerAction('secondary')"
          >
            <v-icon>{{ icons.close }}</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text 
          v-html="blocker.text" 
          class="text-body-1 py-2"
        />
        
        <v-card-actions class="py-4">
          <v-btn
            text
            color="secondary"
            @click="blockerAction('secondary')"
          >
            {{ blocker.actions.secondary }}
          </v-btn>

          <v-spacer />

          <v-btn
            depressed
            :disabled="blocker.disabled"
            color="secondary"
            class="px-4"
            @click="blockerAction('primary')"
          >
            {{ blocker.actions.ok }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay>

    <update-dialog 
      :toggle="updateApp"
      @toggle="toggleUpdate"
    />

    <!-- <m-stories
      :toggle="onboarding.toggle"
      :seen="onboarding.seen"
      :start="onboarding.story"
      :items="onboardingMedia"
      @close="toggleOnboarding"
    /> -->

    <v-overlay 
      :value="loading"
      color="background"
      fixed
      opacity="1"
      z-index="3"
      class="loading-overlay text-center"
    >
      <div class="d-flex flex-column grey--text pa-8 pt-16">
        <loading 
          class="loading mb-6" 
        />
        <span class="d-block text-overline text-secondary">
          Carregando
        </span>
        <span 
          class="text-caption mx-2 mt-16"
        >
        Caso esteja com dificuldades para acessar, mande um email para <a href='mailto: suporte@mobees.com.br'>suporte@mobees.com.br</a>
        </span>
      </div>
    </v-overlay>

    <m-nav
      :toggle="!loading&&!blocker.blocked"
      :pages="pages"
    />

    <toast />
  </div>
</template>

<style type="text/css">

  #main {
    padding: 0 !important;
  }
  
  #home {
    padding-top: 56px !important;
    padding-bottom: 80px !important;
  }

  #home .home-content {
    position: relative;
    margin: env(safe-area-inset-top) 0 0 env(safe-area-inset-left) !important;
  }
  
  #home .page {
    position: relative;
    padding-top: 16px;
  }
  #home .page .empty-state {
    min-height: 80vh;
  }

  .alert-dialog {
    overflow: visible !important;
  }

  .alert-dialog .alert-dialog-text {
    max-height: 60vh;
  }

  .overbar {
    border-top: 4px solid !important;
  }

  .v-content {
    padding-top: 0 !important;
  }

  .v-toolbar .title {
    font-size: 1.125rem !important;
    font-weight: 500;
    letter-spacing: 0.0625rem !important;
    line-height: 1.75rem;
    opacity: .875;
  }

  .loading-overlay .loading {
    width: 56px;
    height: 56px;
    opacity: 1;
  }

</style>

<script>
  import { 
    mdiHelpCircleOutline,
    mdiRefresh,
    mdiClose,
    mdiCheck,
    mdiMessageAlertOutline
  } from '@mdi/js';
  import services from '@/services'
  import { defaults as profileDefaults } from '@/services/profile.js'
  import { pages } from '@/services/home'
  import dictionary from '@/dictionary'
  import { sync } from 'vuex-pathify'
  import { App } from '@capacitor/app';
import { Browser } from '@capacitor/browser';

  const moment = require('moment');

  export default {
    name: 'Index',

    props: {
      scroll: {
        type: Number,
        default: 0
      },
      height: {
        type: Number,
        default: 0
      },
      active: {
        type: Boolean,
        default: true
      },
      installed: {
        type: Boolean,
        default: false
      },
      modal: {
        type: String,
        default: null
      },
      dev: {
        type: Boolean,
        default: false
      }
    },

    components: {
      mNav: () => import('@/components/mNav'),
      Profile: () => import('./Profile'),
      Referrals: () => import('./Referrals'),
      Help: () => import('@/views/help/Help'),
      Toast: () => import('@/components/Toast'),
      Tos: () => import('@/views/help/tos'),
      PrivacyPolicy: () => import('@/views/help/PrivacyPolicy'),
      UpdateDialog: () => import('@/components/UpdateDialog'),
      Loading: () => import('@/components/IconLoading'),
      // mStories: () => import('@/components/mStories'),
    },

    data: () => ({
      api: process.env.VUE_APP_ROOT_API,
      icons: {
        mdiHelpCircleOutline,
        mdiRefresh,
        close: mdiClose,
        check: mdiCheck,
        message: mdiMessageAlertOutline
      },
      date: {
        now: moment(),
        format: 'DD/MM',
        keeper: null,
        updatedAt: null
      },
      pages,
      view: {
        goal: {
          height: 0,
          switchAt: '2023-01-14 23:59:59'
        },
        alert: {
          toggle: false,
          notification: null,
          dismissable: false
        },
        review: {
          loading: false,
          updatedAt: null,
          dismissedAt: null,
          data: {
            history: [],
            reviewedAt: '',
            reviewedBefore: '',
          }
        },
        profile: {
          toggle: false,
          updated: false,
          updatedAt: null
        },
        notifications: {
          toggle: false,
          updated: false,
          updatedAt: null
        },
        help: {
          toggle: false,
          updated: false,
          updatedAt: null
        },
      },
      blocker: {
        toggle: false,
        blocked: true,
        headline: 'Revisão Periódica',
        text: 'Sua revisão expirou! A revisão do equipamento é muito importante <b>para garantir a sua segurança e o funcionamento correto do Buzzer</b>. <br><br>Faça a revisão o mais rápido possível, por favor.',
        actions: {
          ok: 'Ok',
          secondary: 'Ajuda',
        },
        blockedBy: null,
        disabled: false
      },
      dictionary,
      helpContent: null,
      onboardingMedia: [],
      checklistAt: null,
      legal: null,
      updateApp: false,
      appURL: process.env.VUE_APP_ROOT,
    }),

    computed: {
      views: sync('app/views'),
      referrals: sync('app/views@referrals'),
      payments: sync('app/views@payments'),
      bonus: sync('app/views@bonus'),
      profile: sync('app/views@profile'),
      help: sync('app/views@help'),
      calendar: sync('app/views@help.calendar'),
      ticket: sync('app/views@help.ticket'),
      tickets: sync('app/views@help.tickets'),
      events: sync('events/data@items'),
      event: sync('events/data@selected'),
      advertising: sync('app/views@help.advertising'),
      onboarding: sync('app/views@onboarding'),
      standbyConfirmation: sync('app/standbyConfirmation'),
      buzzer: sync('app/buzzer'),
      user: sync('user'),
      toast: sync('app/toast'),
      version: sync('app/version'),

      loading () {
        const reviews = this.view.review.updatedAt;
        const profile = this.view.profile.updatedAt;
        return _.some([reviews, profile], view => view==null);
      },

      isViewOpen () {
        return _.some(this.view, view => _.has(view, 'toggle')&&view.toggle);
      },

      reviewCheck () {
        const signedupAt = this.$moment.utc(this.user.statusChange).local().format('YYYY-MM-DD');
        const reviewedAt = this.view.review.data.reviewedAt;

        const tickets = this.tickets;
        const online = _.find(_.omitBy(tickets, 'closed'), t => {
          return t.section=='review'&&_.has(t.category, 'child')&&t.category.child=='Revisão Online';
        });

        const total = _.isEmpty(signedupAt)||_.isNil(signedupAt) ? 0 : moment().diff(signedupAt, 'd');
        const last = _.isNil(reviewedAt) || _.isEmpty(reviewedAt) ? total : moment().diff(reviewedAt, 'd');
        const expiresIn = 30-last;
        const required = last>=20;
        const delayable = expiresIn<=10 && expiresIn>5;
        const expired = expiresIn<=0;
        const expiresAt = moment().add(expiresIn, 'days').format('YYYY-MM-DD');
        const onlineStatus = !_.isNil(online) ? _.has(online.data, 'status') ? online.data.status : 0 : null;
        return {
          required: required,
          title: !required||onlineStatus==1 ? 'Revisão' : 'Agende sua revisão',
          text: onlineStatus!=null ? 
            (onlineStatus==1 ? 'Sua revisão técnica está sendo avaliada. Caso haja algum problema, precisaremos que você vá até a Colmeia.' : onlineStatus==2 ? 'Identificamos problemas na revisão online e precisamos que vá a Colmeia. *Aperte aqui para agendar*.' : 'Sua revisão online está pendente. O prazo para conclusão é de 48h. *Aperte aqui para continuar*') : 
            !required ? 'Sua revisão técnica está em dia. A próxima deve ser realizada *em até '+moment.duration(expiresIn, 'days').humanize()+'*.' : (expired ? 'O prazo da sua revisão técnica expirou!' : 'Sua revisão técnica *expira em '+moment.duration(expiresIn, 'days').humanize()+'*!') + ' Aperte aqui para agendar.',
          expired: expired||onlineStatus==2,
          expiresAt,
          expiresIn,
          delayable,
          online: onlineStatus,
          last,
        }
      },

      alerts () {
        let tickets = this.tickets;
        tickets = _.omitBy(tickets, 'closed');
        let alerts = [];
        const sections = this.helpContent;
        let pedingScheduledTicket = false;

        if (!_.isEmpty(tickets)&&!!sections) {
          _.each(_.filter(tickets, ['closed', false]), t => {
            const last = _.last(t.messages);
            const script = sections[t.section];
            if ((_.has(last, 'support')&&last.support&&!last.read)||_.has(last, 'assignee')&&last.assignee=='MEL') {
              alerts.push({
                issue: t.section,
                ticket: t.id,
                type: 'message',
                priority: 70,
                title: 'Nova mensagem',
                text: 'Você tem uma nova mensagem da nossa Equipe de Suporte técnico. *Toque aqui para ver*.'
              })
            }else if ((_.has(t.data, 'status')&&t.data.status==0)||(_.has(script, 'props')&&_.has(script.props, 'alert')&&(_.isArray(script.props.alert) ? _.some(script.props.alert, a => t.data[a.key]==a.value) : _.has(t.data, script.props.alert.key)&&t.data[script.props.alert.key]==script.props.alert.value))) {
              pedingScheduledTicket = pedingScheduledTicket ? pedingScheduledTicket : _.has(t.data, 'type')&&t.data.type=='scheduled';
              let message = 'O chamado '+t.summary+' está em aberto. *Toque aqui para continuar*';
              let expiration = null;
              if (_.has(script, 'props')&&_.has(script.props, 'expiration')) {
                expiration = moment(moment.utc(t.createdAt).add(script.props.expiration[0], script.props.expiration[1])).toNow(true);
                message = 'O chamado '+t.summary+' expira em '+expiration+'. *Toque aqui para continuar*';
              }
              if (_.has(script, 'props')&&_.has(script.props, 'alert')&&(t.data[script.props.alert.key]==script.props.alert.value||_.some(script.props.alert, a => t.data[a.key]==a.value))) {
                const alert = _.isArray(script.props.alert) ? _.find(script.props.alert, a => t.data[a.key]==a.value) : script.props.alert;
                message = _.has(script.props, 'expiration') ? alert.message.replace(`{{${script.props.expiration[1]}}}`, expiration) : alert.message;
              }
              alerts.push({
                issue: t.section,
                ticket: t.id,
                type: 'message',
                priority: 55,
                title: 'Atendimento',
                text: message
              })
            }
          })
        }
        const calendar = this.calendar;
        const profile = this.user.profile;

        const urgent = this.urgent;
        const modem = this.buzzer.cached > 10000;
        const reviewCheck = this.reviewCheck;
        
        if (!_.isNil(calendar)&&moment().isBefore(calendar.start)) { 
          const absences = _.reduce(profile.buzzer.absences, (count, absence) => {
            const c = _.filter(absence, element => {
              return moment().subtract(90, 'days').isSameOrBefore(element)
            })
            return c.length + count
          }, 0) 
          alerts.push({
            issue: calendar.issue,
            ticket: _.has(calendar, 'ticket') ? calendar.ticket : null,
            type: 'calendar',
            priority: 60,
            title: calendar.title,
            text: calendar.text,
            absences
          });
        }else{
          if (!_.isNil(urgent)) {
            alerts.push({
              issue: 'urgent',
              ticket: urgent.id,
              type: 'urgent',
              critical: true,
              priority: 80,
              title: 'Manutenção do Buzzer',
              text: 'Precisamos realizar um ajuste. Poderia agendar um horário na Colmeia, por favor? *Aperte aqui para agendar.*',
            });
          }else if (modem) {
            alerts.push({
              issue: 'modem',
              type: 'urgent',
              critical: true,
              required: true,
              priority: 70,
              title: 'Manutenção do Buzzer',
              text: 'A conexão de internet do seu Buzzer está com problemas, precisamos realizar um ajuste. *Aperte aqui para agendar.*',
            });
          }else{
            const loading = this.help.loading;
            alerts.push({
              issue: 'review',
              type: 'review',
              critical: !(!reviewCheck.required||reviewCheck.online==1),
              expired: reviewCheck.expired,
              required: reviewCheck.required,
              priority: reviewCheck.required ? 50 : 30,
              hidden: !reviewCheck.required||pedingScheduledTicket,
              title: reviewCheck.title,
              text: reviewCheck.text,
              data: { ...reviewCheck },
              loading
            });
          }
        }

        if (this.nextEvent) {
          const event = this.nextEvent;
          const title = this.dictionary.events[event.type] +' '+ event.title;
          alerts.push({
            event: event.id,
            type: 'event',
            priority: 60,
            title: 'Eventos',
            text: event.mobilizesAt==null||moment().add(1,'h').isBefore(event.mobilizesAt) ? 
              `Sua participação está confirmada no ${title}${moment(event.mobilizesAt).isValid() ? ' *dia '+moment(event.mobilizesAt).format('DD/MM HH:mm')+'*' : ''}. Fique atento ao dia e horário!` : 
              (moment().isBefore(event.mobilizesAt) ? `O ${title} vai começar às ${moment(event.mobilizesAt).format('HH:mm')}!` : `O ${title} está acontecendo!`) + ' *Clique aqui e siga as orientações*.'
          });
        }

        // const uninstallTicket = this.findTicket('uninstall');
        // if (!uninstallTicket) {
        //   alerts.push({
        //     type: 'urgent',
        //     priority: 100,
        //     title: 'Retirada',
        //     text: 'Aperte aqui para agendar a retirada do Buzzer',
        //     open: {
        //       view: 'help',
        //       section: 'uninstall'
        //     }
        //   });
        // }

        const legal = this.legal;
        alerts.push({
          type: 'info',
          priority: !!legal ? 0 : 60,
          title: 'Blitz & Multas',
          text: 'Em caso de abordagem fical ou policial *clique aqui* para ver e apresentar a documentação.',
          color: !!legal ? {
            title: 'secondary--text text--lighten-1',
            text: 'white--text',
            background: 'secondary darken-4'
          } : null,
          click: ($) => {
            $.setStorage('legal', true);
            $.open({
              type: 'info',
              open: {
                view: 'help',
                section: 'manual',
                item: '13'
              }
            });
          }
        });

        if (this.advertising.toggle) {
          alerts.push({
            issue: 'advertising',
            type: 'schedule',
            priority: 50,
            title: 'Ação Publicitária',
            text: 'Agende seu horário. Os detalhes desta ação estão no WhatsApp'
          });
        } 
        return alerts;
      },

      nextEvent () {
        const events = this.events;
        return _.reduce(_.filter(events, event => {
          const status = _.first(event.drivers);
          return !!status.acceptedAt&&status.declinedAt==null;
        }), (next, event) => {
          next = (event.demobilizesAt==null||moment().isBefore(event.demobilizesAt))&&(next==null||moment(event.mobilizesAt).isBefore(next.mobilizesAt)) ? event : next;
          return next;
        }, null);
      },

      urgent () {
        const tickets = _.omitBy(this.tickets, 'closed');
        return _.find(tickets, ['section', 'urgent']);
      },

      inactivity () {
        const tickets = _.omitBy(this.tickets, 'closed');
        return _.find(tickets, ['section', 'inactivity']);
      },

      survey () {
        const tickets = _.omitBy(this.tickets, 'closed');
        return _.find(tickets, { section: 'survey', data: { status: 0 }})
      },

      block () {
        const updateApp = this.updateApp;
        const alert = this.view.alert.toggle;
        const blocker = this.blocker.toggle;
        return blocker && !updateApp && !alert;
      },

      opacity () {
        const limit = 128;
        const opacity = this.scroll < limit ? (this.scroll / limit) - .16 : .84;
        return opacity;
      },

      hive () {
        const profile = this.user.profile;
        const hive = _.isNil(profile.hive) ? profileDefaults.hive : profile.hive;
        const uri = 'geo:0,0?q=' + _.replace(_.join([hive.address, hive.city], ', '), ' ', '+');
        return {
          ...hive,
          uri
        }
      },

    },

    watch: {
      modal: {
        immediate: true,
        handler (view) {
          if (!!view&&_.has(this.view, view)) {
            // open view
            if (view=='help') {
              const route = this.$route;
              this.view.help.section = route.query.section;
              this.view.help.context = route.name;
            }
            this.view[view].toggle = true;
          }else{
            _.each(this.view, view => {
              if (_.has(view, 'toggle')&&view.toggle) view.toggle = false;
            })
          }
        }
      },
      active: {
        immediate: true,
        handler (active) {
          if (active) {
            // update Home data
            if (!this.view.review.loading) this.updateData();
          }
        }
      },
      'view.review.history' (reviews) {
        this.toggleBlocker();
      },
      help (help) {
        this.toggleBlocker();
      },
      profile (profile) {
        this.toggleBlocker();
      },
      tickets: {
        deep: true,
        handler (tickets) {
          this.toggleChecklist();
        }
      },
      helpContent: {
        immediate: true,
        deep: true,
        handler (content) {
          if (!!content&&_.has(content.controller, 'maintenance')&&content.controller.maintenance) {
            this.$router.replace({ path: '/maintenance' });
          }
        }
      },
      survey: {
        immediate: true,
        handler (survey) {
          const view = this.view.alert;
          if (!_.isNil(survey)) {
            if (_.has(this.helpContent, 'survey')) {
              const text = _.has(survey, 'data') && _.has(survey.data, 'alert') ? survey.data.alert : _.find(this.helpContent.survey.props.alert, { key: 'status', value: 0 }).message;
              const alert = {
                survey: true,
                title: survey.category.child,
                reference: 'ticket#'+survey.id,
                text,
              }
              this.toggleAlert(true, alert);
            }
          }else if (_.isNil(survey)&&view.toggle&&view.notification.survey===true) {
            view.toggle = false;
          }
        }
      }
    },

    filters: {
      formatCycle (period) {
        return period!=null&&period.start!=null ? moment(period.start).format('DD MMM') + ' a ' + moment(period.end).format('DD MMM') : '';
      }
    },

    methods: {
      ...services,

      toggleChecklist () {
        const checklistAt = this.checklistAt;
        const signedupAt = this.user.statusChange;
        if (checklistAt===null&&!!this.tickets) {
          const ticket = _.find(this.tickets, ticket => ticket.section=='checklist'&&(ticket.closed||ticket.data.status==1));
          const daysSinceSignup = moment().diff(signedupAt, 'd');
          const checklist = daysSinceSignup>=1 && !!ticket || daysSinceSignup<=15;
          if (!checklist) {
            this.toggleHelp(true, null, 'checklist');
          }else{
            this.setStorage('checklistAt', moment().valueOf());
          }
        }
      },

      toggleBlocker () {
        const profile = this.profile.pending;
        const hive = this.hive;
        const review = this.view.review.updatedAt==null ? null : this.reviewCheck;
        const urgent = this.urgent;
        const inactivity = this.inactivity;
        const calendar = _.isNil(this.calendar)||moment().isAfter(this.calendar.start) ? null : this.calendar;
        const dismissable = _.isNil(urgent)&&!_.isNil(review)&&review.delayable;
        const dismissedAt = this.view.review.dismissedAt;
        const blockedBy = {
          review: !this.dev&&!_.isNil(review)&&(review.expired||(review.required&&((_.isNil(review.online) ? _.isNil(calendar) : review.online!=1)&&(!dismissable||_.isNil(dismissedAt)||moment().diff(dismissedAt, 'days')>=1)))),
          issue: (!_.isNil(urgent)&&(_.isNil(calendar)||calendar.issue!='urgent'))&&!_.isNil(inactivity),
          profile
        }
        console.log(blockedBy.profile);

        // block app funcionalities w/ dialog
        this.blocker.toggle = !this.help.toggle&&(blockedBy.profile||blockedBy.review||blockedBy.issue);
        this.blocker.blockedBy = blockedBy;
        this.blocker.blocked = (blockedBy.profile||(blockedBy.review&&!dismissable)||blockedBy.issue);
        // console.log('blockedBy', blockedBy, this.blocker.toggle);
        if (profile) {
          this.blocker.headline = 'Atualize seu perfil';
          this.blocker.text = 'Existem informações pendentes de atualização no seu Perfil. Preencha os campos sinalizados, por favor.';
          this.blocker.actions.ok = 'Atualizar';
          this.blocker.actions.secondary = 'Ajuda';
        }else if (urgent) {
          this.blocker.headline = 'Manutenção do Buzzer';
          const scheduled = urgent&&!_.isNil(calendar)&&calendar.issue=='urgent';
          this.blocker.text = scheduled ? 'Compareça a Colmeia <b>dia '+moment(calendar.start).format('DD/MM')+' às '+moment(calendar.start).hour()+'h</b>. <a href="'+hive.uri+'" target="_blank">'+hive.address+'</a>.' : 'Identificamos um problema com o seu Buzzer e precisamos realizar um ajuste na Colmeia.<br><br>Agende uma visita o mais rápido possível, por favor.';
          this.blocker.actions.ok = scheduled ? 'Agendado' : 'Agendar';
          this.blocker.actions.secondary = 'Ajuda';
          this.blocker.disabled = scheduled;
        }else if (inactivity) {
          this.blocker.headline = 'Inatividade';
          this.blocker.text = 'Identificamos falta de atividade do Buzzer nos últimos dias.<br><br> Responda as perguntas a seguir, por favor.';
          this.blocker.actions.ok = 'Ok';
          this.blocker.actions.secondary = 'Ajuda';
        }else if (!_.isNil(review)&&review.expired&&review.online==2) {
          this.blocker.text = 'Identificamos um problema na sua Revisão Online e precisamos realizar um ajuste na Colmeia. <br><br>Agende a revisão o mais rápido possível, por favor.';
          this.blocker.disabled = false;
          this.blocker.actions.ok = 'Agendar';
          this.blocker.actions.secondary = 'Ajuda';
        }else if (this.blocker.toggle&&!_.isNil(calendar)&&moment().isBefore(calendar.start)) {
          this.blocker.headline = calendar.title;
          this.blocker.text = 'Compareça a Colmeia <b>dia '+moment(calendar.start).format('DD/MM')+' às '+moment(calendar.start).hour()+'h</b>. <a href="'+hive.uri+'" target="_blank">'+hive.address+'</a>.';
          this.blocker.actions.ok = 'Agendado';
          this.blocker.actions.secondary = 'Ajuda';
          this.blocker.disabled = true;
        }else if (!_.isNil(review)&&!review.expired&&review.required) {
          this.blocker.dismissable = dismissable;
          if (dismissable) this.blocker.actions.secondary = 'Depois';
          this.blocker.headline = 'Agende sua revisão';
          this.blocker.text = 'Sua revisão periódica vai expirar em '+review.expiresIn+' dia'+(review.expiresIn>1 ? 's' : '')+'. A revisão do equipamento é muito importante <b>para garantir a sua segurança e o funcionamento correto do Buzzer</b><br><br>' + (dismissable ? 'Agende o quanto antes. 😉' : 'Agende já a sua próxima revisão.');
        }else if (!_.isNil(review)&&review.online==1) {
          this.blocker.headline = 'Revisão Periódica';
          this.blocker.text = 'Sua revisão está sendo avaliada pela equipe técnica. <br><br>Caso haja algum problema, solicitaremos que você vá até a Colmeia para realizar manutenção.';
          this.blocker.actions.ok = 'Aguarde';
          this.blocker.disabled = true;
        }
        if (!dismissable) this.blocker.actions.secondary = 'Ajuda';
      },

      toggleAlert (b, notification) {
        if (this.updateApp) return;

        const alert = this.view.alert;
        if (b) {
          alert.notification = notification;
          alert.notification.text = this.formatText(notification.text);
          this.$nextTick(() => {
            this.$refs['alert-text'].addEventListener('click', (e) => {
              console.log(e);
              if ('open' in e.target.dataset) {
                e.preventDefault();
                this.open(e.target.href);
              }
            });
          })
          setTimeout((alert) => {
            alert.dismissable = true;
          }, 3000, alert);
        }else{
          if (_.has(alert.notification, 'id')) this.setNotifications(alert.notification);
          const reference = alert.notification.reference;
          if (reference!=null) {
            const [type, id] = _.split(reference, '#');
            if (type=='ticket') {
              this.toggleTicket(id);
            }else if (type=='flashmob') {
              this.toggleEvent(id);
            }
          }
          alert.dismissable = false;
        }
        alert.toggle = b;
      },

      blockerAction (trigger) {
        const controller = this.blocker;
        if (trigger=='primary') {
          if (controller.blockedBy.profile) {
            this.toggleView('profile');
          }else if (!_.isNil(this.urgent)) {
            if (_.isNil(this.calendar)||_.isNil(this.calendar.ticket)) {
              this.toggleTicket(this.urgent.id);
            }else{
              this.toggleTicket(this.calendar.ticket);
            }
          }else if (!_.isNil(this.inactivity)) {
            this.toggleTicket(this.inactivity.id);
          }else{
            this.toggleHelp(true, 'review', 'review');
          }
        }else{
          if (this.blocker.dismissable) {
            this.view.review.dismissedAt = moment().startOf('day').valueOf();
            this.blocker.toggle = false;
          }else{
            this.toggleHelp(true);
          }
        }
      },

      findTicket (section, status=null, closed=null) {
        const tickets = this.tickets;
        return _.find(tickets, ticket => {
          // console.log(ticket.closedAt, moment().diff(ticket.closedAt, 'd'));
          return (closed==null||ticket.closed==closed) && ticket.section==section && (status===null||(ticket.data?.status==status));
        });
      },

      toggleUpdate (b) {
        this.updateApp = b;
      },

      toggleOnboarding (b, seen, resume) {
        this.onboarding.toggle = b;
        if (!this.onboarding.seen) {
          this.onboarding.seen = !_.isNil(seen) ? seen : this.onboarding.seen;
          this.onboarding.story = !_.isNil(resume) ? resume : this.onboarding.story;
        }
        console.log('onboarding toggle', this.onboarding.toggle);
      },

      setReviews (data) {
        this.view.review.loading = true;
  
        const reviews = _.isNil(data.revisoes) ? [] : _.orderBy(data.revisoes, ['data'], ['desc']);
        this.view.review.data.history = reviews;
        if (!_.isEmpty(reviews)) {
          this.view.review.data.reviewedAt = reviews[0].data;
          if (_.size(reviews)>=2) this.view.review.data.reviewedBefore = reviews[1].data;
        }
        this.view.review.updatedAt = moment().valueOf();
        this.toggleBlocker();
        setTimeout(($) => {
          $.view.review.loading = false;
        }, 500, this);
      },

      getNotifications () {
        this.views.notifications.loading = true;
        const cpf = this.rawCPF(this.user.cpf);
        const token = this.user.auth.token;

        this.$api
          .get('/getdrivernotifications/'+cpf+'/'+token+'/?format=json')
          .then(response => {
            console.log('getNotifications => ', response);
            if(response.data.retorno!=400){
              const notifications = _.mapValues(_.keyBy(response.data.notifications, 'id'), notification => {
                return {
                  ...notification,
                  sent: moment.utc(notification.sent).local().format('YYYY-MM-DD HH:mm:ss')
                }
              });
              const alert = _.find(notifications, { type: 'ALERT', read: null });
              if (!_.isNil(alert)) {
                this.toggleAlert(true, alert);
              }
              this.views.notifications.items = Object.assign({}, notifications);
              this.views.notifications.updatedAt = moment().valueOf();
              this.views.notifications.loading = false;
            }else{
              // this.toggleToast(
              //   true,
              //   'Erro ao carregar notificações',
              //   5000,
              //   false,
              // );
            }
          })
          .catch(error => {
            setTimeout(($) => {
              $.getNotifications();
            }, 7000, this);
            this.handleError(error, false);
          })
          .finally(() => {
          });
      },

      setNotifications (read) {
        const cpf = this.rawCPF(this.user.cpf);
        const token = this.user.auth.token;

        read = _.map(_.isArray(read) ? read : [read], n => {
          return { id: n.id, read: moment.utc().format('YYYY-MM-DD HH:mm:ss') }
        })

        this.$api
          .post('/setdrivernotifications/'+cpf+'/'+token+'/?format=json', read)
          .then(response => {
            console.log('setNotifications => ', response);
            if (response.data.retorno!=401) {
              const notifications = _.keyBy(read, 'id');
              this.views.notifications.items = _.merge({}, this.views.notifications.items, notifications);
            }
            this.view.alert.notification = null;
          })
      },

      getEvents () {
        const cpf = this.rawCPF(this.user.cpf);
        const token = this.user.auth.token;

        const data = {
          cpf,
          token,
        };
        
        console.log('getEvents...', data);
        this.$api
          .get('driver/events/', { params: data })
          .then(response => {
            console.log('getEvents => ',response);

            this.events = Object.assign({}, _.keyBy(_.map(response.data, item => {
              return this.format(item, this.dictionary.events);
            }), 'id'));
            console.log('events', this.events);
          })
          .catch(error => {
            setTimeout(($) => {
              $.getEvents();
            }, 7000, this);
            this.handleError(error, false);
          })
      },

      updateData (force) {
        if (force===true||this.date.updatedAt==null||moment().diff(this.date.updatedAt, 'minutes')>5) {
          this.date.updatedAt = moment().valueOf();
          this.getProfile();
          this.getNotifications();
          this.getEvents();
          this.loadHelpContent(false);
        }
      },

      getProfile () {
        this.$nextTick(() => {
          if (_.has(this.$refs, 'profile')) {
            new Promise((resolve) => {
              this.$refs['profile'].getProfile(resolve);
            }).then((data) => {
              console.log(data);
              this.setReviews(data);
              this.view.profile.updated = true;
              this.view.profile.updatedAt = moment().valueOf();
            });
          }else{
            setTimeout(($) => {
              $.getProfile();
            }, 200, this);
          }
        })
      },
    },

    mounted () {
      // set GA UserType dimension
      this.$gtag.customMap({ 'dimension1': 'UserType' });
      this.$gtag.set({
        'UserType': 'Parceiro'
      });

      this.getStorage('checklistAt').then(value => {
        this.checklistAt = value;
      });

      this.date.keeper = setInterval(() => {
        this.date.now = moment();
      }, 30000);

      this.getStorage('legal').then(v => {
        this.legal = v;
      });

      this.loadHelpContent(false);

      App.addListener('appRestoredResult', ({ pluginId, methodName, data, success }) => {
        console.log('Restored state:', pluginId, methodName, JSON.stringify(data));
        this.trackEvent('state', `${pluginId} ${methodName}`);
        this.getStorage('camera').then(state => {
          if (!!state) {
            state.data = data;
            this.setStorage('camera', state).then(() => {
              this.toggleTicket(state.ticket);
            });
          }
        });
      });
    },
    beforeDestroy () {
      clearInterval(this.date.keeper);
    }
  }
</script>
